export default function GlobeIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 13C25.5 11.3585 25.1767 9.73303 24.5485 8.21646C23.9203 6.69989 22.9996 5.3219 21.8388 4.16117C20.6781 3.00043 19.3001 2.07969 17.7835 1.45151C16.267 0.823322 14.6415 0.5 13 0.5C9.68479 0.5 6.50537 1.81696 4.16117 4.16117C1.81696 6.50537 0.5 9.68479 0.5 13C0.5 16.3152 1.81696 19.4946 4.16117 21.8388C6.50537 24.183 9.68479 25.5 13 25.5C16.3152 25.5 19.4946 24.183 21.8388 21.8388C24.183 19.4946 25.5 16.3152 25.5 13V13ZM22.9125 11.75H19.25C19.0438 8.81018 18.0366 5.98294 16.3375 3.575C18.0831 4.19496 19.6222 5.28759 20.7831 6.73105C21.9441 8.17452 22.6813 9.91208 22.9125 11.75V11.75ZM9.35 14.25H16.75C16.4301 17.3382 15.1138 20.2385 13 22.5125C10.8876 20.248 9.60149 17.3366 9.35 14.25V14.25ZM9.35 11.75C9.64111 8.67728 10.922 5.78215 13 3.5C15.1309 5.75765 16.4502 8.66005 16.75 11.75H9.35ZM9.8 3.5375C8.09194 5.95878 7.0685 8.7959 6.8375 11.75H3.0875C3.32363 9.88937 4.07826 8.13265 5.26521 6.68047C6.45216 5.22828 8.02357 4.13916 9.8 3.5375V3.5375ZM3.0875 14.25H6.8375C7.04336 17.2016 8.05985 20.0391 9.775 22.45C8.00497 21.8454 6.43998 20.7565 5.25784 19.307C4.07569 17.8575 3.32377 16.1055 3.0875 14.25V14.25ZM16.275 22.4375C17.9855 20.0258 19.0133 17.1972 19.25 14.25H22.9375C22.7011 16.1003 21.9519 17.8476 20.7746 19.2944C19.5972 20.7413 18.0386 21.83 16.275 22.4375V22.4375Z"
        fill="#0300AE"
      />
    </svg>
  );
}
