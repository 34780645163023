export default function Facebook() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 0.75C1.87125 0.75 0.75 1.87125 0.75 3.25V20.75C0.75 22.1287 1.87125 23.25 3.25 23.25H11.5264H15.0176H20.75C22.1287 23.25 23.25 22.1287 23.25 20.75V3.25C23.25 1.87125 22.1287 0.75 20.75 0.75H3.25ZM3.25 3.25H20.75L20.7524 20.75H15.0176V15.7939H17.9741L18.4136 12.3882H15.0371V10.2178C15.0371 9.23277 15.3116 8.56738 16.7241 8.56738H18.5088V5.52783C17.64 5.43908 16.7678 5.39594 15.894 5.39844C13.3015 5.39844 11.5264 6.98074 11.5264 9.88574V12.3931H8.59668V15.7988H11.5264V20.75H3.25V3.25Z"
        fill="#272500"
      />
    </svg>
  );
}
