export default function StarIcon() {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1836 1.93733C14.8985 0.387587 17.101 0.387586 17.8159 1.93733L20.8915 8.60518C21.1828 9.23679 21.7814 9.67168 22.4721 9.75358L29.764 10.6182C31.4588 10.8191 32.1394 12.9139 30.8864 14.0726L25.4953 19.0581C24.9847 19.5304 24.756 20.2341 24.8916 20.9163L26.3226 28.1185C26.6553 29.7924 24.8733 31.087 23.3841 30.2534L16.9766 26.6668C16.3697 26.3271 15.6298 26.3271 15.0229 26.6668L8.61541 30.2534C7.12619 31.087 5.34427 29.7924 5.67688 28.1185L7.10795 20.9163C7.2435 20.2341 7.01487 19.5304 6.5042 19.0581L1.1131 14.0726C-0.139899 12.9139 0.540734 10.8191 2.23552 10.6182L9.52743 9.75358C10.2182 9.67168 10.8167 9.23679 11.1081 8.60518L14.1836 1.93733Z"
        fill="#FFD76F"
      />
    </svg>
  );
}
