export default function Insta({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ? fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0.75C3.5 0.75 0.75 3.5 0.75 7V17C0.75 20.5 3.5 23.25 7 23.25H17C20.5 23.25 23.25 20.5 23.25 17V7C23.25 3.5 20.5 0.75 17 0.75H7ZM7 3.25H17C19.125 3.25 20.75 4.875 20.75 7V17C20.75 19.125 19.125 20.75 17 20.75H7C4.875 20.75 3.25 19.125 3.25 17V7C3.25 4.875 4.875 3.25 7 3.25ZM18.25 4.5C17.5 4.5 17 5 17 5.75C17 6.5 17.5 7 18.25 7C19 7 19.5 6.5 19.5 5.75C19.5 5 19 4.5 18.25 4.5ZM12 5.75C8.5 5.75 5.75 8.5 5.75 12C5.75 15.5 8.5 18.25 12 18.25C15.5 18.25 18.25 15.5 18.25 12C18.25 8.5 15.5 5.75 12 5.75ZM12 8.25C14.125 8.25 15.75 9.875 15.75 12C15.75 14.125 14.125 15.75 12 15.75C9.875 15.75 8.25 14.125 8.25 12C8.25 9.875 9.875 8.25 12 8.25Z" />
    </svg>
  );
}
